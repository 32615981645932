import { Text } from "@chakra-ui/react";
import { NavItem } from "./AppNav";

interface AppNavItemHeadProps {
    navItem: NavItem;
}

export const AppNavItemHead = ({ navItem }: AppNavItemHeadProps) => {
    return (
        <Text
            textStyle={"regularXs"}
            m="2"
            color={"var(--chakra-colors-primaryS50)"}
        >
            {navItem.label}
        </Text>
    );
};
