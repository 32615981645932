import { Canceler } from "axios";
import { EntityApi } from "../../services/ApiPlatform";
import { FinalResponse, ListResponse } from "../../services/ApiPlatform/models";

export abstract class DonationTransactionApi extends EntityApi {
    protected static GET_COLLECTION = "/api/donation-transactions";

    protected static POST_COLLECTION = "/api/donation-transactions";

    protected static GET_ITEM = "/api/donation-transactions/{id}";

    protected static PUT_ITEM = "/api/donation-transactions/{id}";

    protected static PATCH_ITEM = "/api/donation-transactions/{id}";

    protected static DELETE_ITEM = "/api/donation-transactions/{id}";

    protected static GET_COLLECTION_MY = "/api/donation-transactions/my";

    public static async getCollectionMy<R>(
        page = 1,
        extraParams = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        extraParams = { ...extraParams, PATH: this.GET_COLLECTION_MY };
        return this.getCollection(page, extraParams, cancelToken);
    }
}
