import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTrans } from "../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotPasswordPageSchema } from "./schema";
import {
    Button,
    HStack,
    Input,
    VStack,
    Box,
    Image,
    useToast,
    Center,
} from "@chakra-ui/react";
import { UserApi } from "../../../apis";
import {
    ForgotPasswordPayload,
    ForgotPasswordResponse,
} from "../../../apis/entities/dtos";
import { AppFormControl, AppLoader } from "../../../components";
import { cPath } from "../../../config";
import logoSrc from "../../../assets/images/logo.svg";

export const ForgotPasswordPage = () => {
    // hooks
    const { t } = useTrans();
    const toast = useToast();

    // state
    const [loading, setLoading] = useState(false);

    // form
    const {
        handleSubmit,
        register,
        formState: { isSubmitting, errors },
        reset,
    } = useForm<ForgotPasswordPayload>({
        resolver: yupResolver(forgotPasswordPageSchema(t)),
    });

    const onSubmitHandler = (formData: ForgotPasswordPayload) => {
        setLoading(true);
        UserApi.postUserChangePasswordRequest<
            ForgotPasswordResponse,
            ForgotPasswordPayload
        >(formData)
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    toast({
                        title: t(
                            "ppub.Security.ForgotPasswordPage:toast.success.passwordReset"
                        ),
                        status: "success",
                    });
                    reset();
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <Box
                maxW={"28rem"}
                py={{ base: 8, sm: 8 }}
                mx={"auto"}
                mt={{ base: 0, sm: 8 }}
            >
                <Center>
                    <Image
                        w={"60%"}
                        src={logoSrc}
                        alt="LC Association"
                        loading="lazy"
                    />
                </Center>
            </Box>
            <Box
                maxW={"28rem"}
                py={{ base: 8, sm: 8 }}
                px={{ base: 4, sm: 10 }}
                borderRadius={"0.5rem"}
                boxShadow={"var(--chakra-colors-boxShadowPrimary)"}
                mx={"auto"}
                bg={"var(--chakra-colors-white)"}
            >
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    {loading && <AppLoader />}
                    <VStack gap={6} alignItems={"stretch"}>
                        <VStack gap={5} alignItems={"stretch"}>
                            <AppFormControl
                                label={t("ent.User:email.label")}
                                isInvalid={!!errors.email}
                                message={errors.email?.message}
                            >
                                <Input
                                    type="email"
                                    placeholder={t(
                                        "ent.User:email.placeholder"
                                    )}
                                    {...register("email")}
                                />
                            </AppFormControl>
                        </VStack>
                        <VStack gap={6} alignItems={"stretch"}>
                            <Button
                                isDisabled={isSubmitting || loading}
                                type="submit"
                            >
                                {t(
                                    "ppub.Security.ForgotPasswordPage:button.sendResetInstructions"
                                )}
                            </Button>
                            <HStack justifyContent={"center"}>
                                <Button
                                    as={NavLink}
                                    to={cPath("PUBLIC.SECURITY.LOGIN_PAGE")}
                                    size="sm"
                                    variant={"secondary"}
                                >
                                    {t(
                                        "ppub.Security.ForgotPasswordPage:button.backToLogin"
                                    )}
                                </Button>
                            </HStack>
                        </VStack>
                    </VStack>
                </form>
            </Box>
        </>
    );
};
