import * as yup from "yup";

export const schema = (t: (val: string) => string) => {
    const validation = yup.object().shape({
        name: yup.string().required(t("cmn:val.required")),
        slug: yup.string().required(t("cmn:val.required")),
        content: yup.string().nullable(),
    });

    return validation;
};
