import {
    Avatar,
    Box,
    Button,
    Divider,
    HStack,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text,
    VStack,
} from "@chakra-ui/react";
import { AppIcon } from "./AppIcon";
import AppNavItem from "../containers/AppNav/AppNavItem";
import { User } from "../apis";
import { NavItem } from "../containers";

interface AppUserNavProps {
    user: User;
    navItems: NavItem[];
}

export const AppUserNav = ({ user, navItems }: AppUserNavProps) => {
    return (
        <HStack gap={1}>
            <Avatar
                w={8}
                h={8}
                name={user?.fullName}
                // src={"https://bit.ly/dan-abramov"}
            />
            <Popover placement="bottom-end" offset={[0, 15]}>
                <PopoverTrigger>
                    <Button
                        size={"sm"}
                        className={"btn-icon-header"}
                        variant={"transparent"}
                    >
                        <AppIcon
                            name={"icl-chevron-bottom-alt"}
                            w="1rem"
                            color={"var(--chakra-colors-primary)"}
                        />
                    </Button>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverBody>
                        <VStack
                            gap={1}
                            py={1}
                            ps={1}
                            alignItems={"stretch"}
                            justify={"start"}
                        >
                            <HStack gap={3} px={4} pb={2}>
                                <Avatar
                                    w={10}
                                    h={10}
                                    name={user?.fullName}
                                    // src={"https://bit.ly/dan-abramov"}
                                />
                                <Box>
                                    <Text textStyle={"heading5"}>
                                        {user?.fullName}
                                    </Text>
                                    <Text
                                        textStyle={"regularSm"}
                                        color={
                                            "var(--chakra-colors-contentT40)"
                                        }
                                    >
                                        {user.email}
                                    </Text>
                                </Box>
                            </HStack>
                            <Divider
                                mb={2}
                                borderColor={"var(--chakra-colors-primaryT80)"}
                            />
                            {navItems?.map((item, index) => (
                                <AppNavItem
                                    key={index}
                                    navItem={item}
                                    variant={"transparent"}
                                />
                            ))}
                        </VStack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </HStack>
    );
};
