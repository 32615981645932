import { useEffect, useRef, useState } from "react";
import { Canceler } from "axios";
import { useToast } from "@chakra-ui/react";
import { DataType, SortingMode } from "ka-table";
import { Column } from "ka-table/models";
import { useAppTable, useFormContainerState, useTrans } from "../../../hooks";
import { AppLoader, AppOptionBoxItem } from "../../../components";
import {
    DonationTransaction as Entity,
    DonationTransactionApi as EntityApi,
} from "../../../apis";
import { APPC, cPath } from "../../../config";
import { useAuthData } from "../../../contexts";

export const useDonationTransactionCrud = (userId: string) => {
    // hooks
    const { t } = useTrans();
    const toast = useToast();
    const formContainerState = useFormContainerState();
    const { user } = useAuthData();

    const isMyDonationPage =
        window.location.pathname ===
        cPath("ADMIN.MY_DONATION_TRANSACTION_PAGE", {});

    const {
        table,
        pageIndex,
        pagesCount,
        pageSize,
        sortColumn,
        sortOrder,
        setPagesCount,
    } = useAppTable("id", "desc");

    // state & const
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState<Entity[]>([]);
    const [data, setData] = useState<Entity>(new Entity());
    const cancelTokenSourcesRef = useRef<Canceler[]>([]);

    // List
    const columns: Column[] = [
        {
            key: "id",
            title: t("cmn:label.id"),
            dataType: DataType.Number,
        },
        {
            key: "createdAt",
            title: t("ent.DonationTransaction:createdAt.label"),
            dataType: DataType.String,
        },
        {
            key: "amount",
            title: t("ent.DonationTransaction:amount.label"),
            dataType: DataType.String,
        },
        {
            key: "user.fullName",
            title: t("ent.User:name.label"),
            dataType: DataType.String,
        },
        {
            key: "status",
            title: t("ent.DonationTransaction:status.label"),
            dataType: DataType.String,
        },
        {
            key: "action",
            title: t("cmn:label.listAction"),
            style: { textAlign: "right" },
            dataType: DataType.Object,
            width: 160,
            isSortable: false,
        },
    ];

    const paging = {
        enabled: true,
        pageSizes: APPC.PAGE_SIZES,
        pageSize,
        pageIndex,
        pagesCount,
    };

    const kaTableProps = {
        table: table,
        columns: columns,
        data: list || [],
        loading: {
            enabled: loading,
            customComponent: loading ? <AppLoader /> : null,
        },
        paging: paging,
        rowKeyField: "id",
        sortingMode: SortingMode.SingleRemote,
    };

    const actionOptions: AppOptionBoxItem[] = [
        {
            key: "detail",
            label: t("cmn:option.detail"),
            iconName: "icl-eye",
        },
    ];

    const actionClickHandler = (key: string, data?: Entity) => {
        if (!data) {
            return;
        }
        switch (key) {
            case "detail":
                formContainerState.open(data?.id);
                break;
        }
    };

    const fetchListData = async () => {
        setLoading(true);
        const params = {
            itemsPerPage: pageSize,
        };
        params[`order[${sortColumn}]`] = sortOrder;

        params["user.id"] = isMyDonationPage ? user?.id : userId;

        const method = isMyDonationPage ? "getCollectionMy" : "getCollection";

        await EntityApi[method]<Entity>(pageIndex + 1, params, (c) => {
            cancelTokenSourcesRef.current.push(c);
        })
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    setPagesCount(Math.ceil(response.totalItems / pageSize));
                    setList(response.items);
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!formContainerState.isOpen) {
            fetchListData();
        }
    }, [pageIndex, pageSize, sortColumn, sortOrder, formContainerState.isOpen]);

    useEffect(() => {
        if (!formContainerState.entityId) {
            return;
        }
        setLoading(true);
        EntityApi.getItem<Entity>(formContainerState.entityId)
            .then(({ errorMessage, isNotFound, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (isNotFound) {
                    toast({
                        title: t("cmn:message.entityNotFound"),
                        status: "error",
                    });
                } else if (response !== null) {
                    console.log(response);
                    setData(response);
                }
            })
            .finally(() => setLoading(false));
    }, [formContainerState.entityId]);

    return {
        formContainerState,
        kaTableProps,
        actionOptions,
        actionClickHandler,
        loading,
        data,
    };
};
