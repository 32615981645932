import { FC } from "react";
import { FormContainerStateReturnType, useTrans } from "../../../hooks";
import { VStack, Input } from "@chakra-ui/react";
import { AppFormControl, AppLoader } from "../../../components";
import { AppFormContainer } from "../../../containers";
import { APPC } from "../../../config";

export const PageForm: FC<{
    formContainerState: FormContainerStateReturnType;
    onFormSubmit;
    register;
    formState;
    data;
    loading: boolean;
}> = ({
    formContainerState,
    onFormSubmit,
    register,
    formState,
    data,
    loading,
}) => {
    // hook
    const { t } = useTrans();

    // state & const
    const { errors } = formState;

    return (
        <>
            {loading ? (
                <AppLoader />
            ) : (
                <>
                    <AppFormContainer
                        title={t("padm.PageForm:text.pageTitle")}
                        state={formContainerState}
                        type={APPC.FORM_CONTAINER}
                    >
                        <form id="app-form" onSubmit={onFormSubmit}>
                            <VStack gap={5} alignItems={"stretch"}>
                                <AppFormControl
                                    label={t("ent.Page:name.label")}
                                    isInvalid={!!errors?.name}
                                    message={errors?.name?.message}
                                >
                                    <Input
                                        type="text"
                                        placeholder={t(
                                            "ent.Page:name.placeholder"
                                        )}
                                        {...register("name")}
                                        defaultValue={data.name}
                                    />
                                </AppFormControl>
                                <AppFormControl
                                    label={t("ent.Page:slug.label")}
                                    isInvalid={!!errors?.slug}
                                    message={errors?.slug?.message}
                                >
                                    <Input
                                        type="text"
                                        placeholder={t(
                                            "ent.Page:slug.placeholder"
                                        )}
                                        {...register("slug")}
                                        defaultValue={data.slug}
                                    />
                                </AppFormControl>
                            </VStack>
                        </form>
                    </AppFormContainer>
                </>
            )}
        </>
    );
};
