import { extendTheme } from "@chakra-ui/react";
import {
    Button,
    Input,
    Select,
    Textarea,
    Checkbox,
    Radio,
    Switch,
    FormLabel,
    FormHelperText,
    FormErrorMessage,
    FormError,
    Modal,
    Drawer,
    Tabs,
    NumberInput,
    Badge,
    Menu,
    Popover,
} from "./components";
import { colors, typography, GlobalStyle } from "./foundations";
const fonts = typography.fonts;
const textStyles = typography.textStyles;

const overrides = {
    styles: GlobalStyle,
    colors,
    fonts,
    textStyles,
    components: {
        Button,
        Input,
        Select,
        Textarea,
        Checkbox,
        Radio,
        Switch,
        FormLabel,
        FormHelperText,
        FormTheme: { FormError: FormErrorMessage },
        FormError,
        Modal,
        Drawer,
        Tabs,
        NumberInput,
        Badge,
        Menu,
        Popover,
    },
};

export default extendTheme(overrides);
