import {
    Box,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Text,
} from "@chakra-ui/react";
import { AppIcon } from "./AppIcon";

export type AppOptionBoxItem = {
    key: string;
    label: string;
    iconName?: string;
    divider?: boolean;
    isDisabled?: boolean;
};

export interface AppOptionsBoxProps<T> {
    options: AppOptionBoxItem[];
    onClick: (key: string, data?: T) => void;
    data?: T;
    iconName?: string;
    iconSize?: string;
}

export const AppOptionsBox = <T,>({
    options,
    data,
    onClick,
    iconName = "icl-options",
    iconSize = "1.25rem",
}: AppOptionsBoxProps<T>) => {
    return (
        <>
            <Menu placement="bottom-end" offset={[-5, -5]}>
                <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={
                        <AppIcon
                            name={iconName}
                            w={iconSize}
                            color={"var(--chakra-colors-primary)"}
                        />
                    }
                    variant="unstyled"
                />
                <MenuList>
                    {options?.map((option) => (
                        <Box key={option?.key}>
                            <MenuItem
                                onClick={() => onClick(option?.key, data)}
                                isDisabled={option?.isDisabled}
                            >
                                <HStack>
                                    {option?.iconName && (
                                        <AppIcon
                                            name={option.iconName}
                                            w={"1rem"}
                                        />
                                    )}
                                    <Text
                                        textStyle={"mediumSm"}
                                        lineHeight={"normal"}
                                        marginLeft={2}
                                    >
                                        {option.label}
                                    </Text>
                                </HStack>
                            </MenuItem>
                            {option?.divider && <MenuDivider />}
                        </Box>
                    ))}
                </MenuList>
            </Menu>
        </>
    );
};
