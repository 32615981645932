import { BaseEntity } from "./BaseEntity";

export class User extends BaseEntity {
    public email: string = "";
    public roles: string[] = [];
    public userType: string = "";
    public plainPassword: string = "";
    public plainPasswordConfirm: string = "";
    public changePwdCode?: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public fullName: string = "";
    public fullNameNs: string = "";
    public address: string = "";
    public postalCode: string = "";
    public city: string = "";
    public country: string = "";
    public isCompany: boolean = false;
    public companyName: string = "";
    public siren: string = "";
    public legalStatus: string = "";
    public avatar?: string;

    constructor(model: Partial<User> = {}) {
        super(model?.id);
        Object.assign(this, model);
    }
}

export type PUser = Partial<User>;
