import {
    useAppUtil,
    useAuthAction,
    useFormContainerState,
    useResolveUploadedPath,
    useTrans,
} from "../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import { useAuthData } from "../../../contexts";
import {
    AppAlert,
    AppFormControl,
    AppIcon,
    AppLoader,
    AppPageHeader,
} from "../../../components";
import {
    User as FormEntity,
    UserApi as FormEntityApi,
    UserApi,
} from "../../../apis";
import {
    Avatar,
    Box,
    Button,
    Divider,
    HStack,
    Input,
    Text,
    VStack,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import {
    ChangeEmailPayload,
    ChangeEmailResponse,
    ForgotPasswordPayload,
    ForgotPasswordResponse,
} from "../../../apis/entities/dtos";
import { lazy, Suspense, useRef, useState } from "react";
import { schemaChangeEmail } from "./schemaChangeEmail";
import { AppFormContainer } from "../../../containers";
import { CropperRef } from "react-advanced-cropper";
import { APPC } from "../../../config";

const Cropper = lazy(() =>
    import("react-advanced-cropper").then((module) => ({
        default: module.Cropper,
    }))
);

export const ProfilePage = () => {
    const { t } = useTrans();
    const toast = useToast();
    const { user } = useAuthData();
    const { reloadUserAction, logoutAction } = useAuthAction();
    const formContainerState = useFormContainerState();
    const { getFileFromBlob } = useAppUtil();
    const {
        isOpen: isOpenChangePassword,
        onOpen: onOpenChangePassword,
        onClose: onCloseChangePassword,
    } = useDisclosure();

    // state & const
    const [loading, setLoading] = useState(false);
    const [changeEmailData] = useState<ChangeEmailPayload>({ newEmail: "" });
    const [uploadedFile, setUploadedFile] = useState<File>();
    const [croppedFile, setCroppedFile] = useState<File | undefined>(undefined);

    // cropper
    const [showCropper, setShowCropper] = useState(false);
    const cropperRef = useRef<CropperRef>(null);
    const onCropperDone = async () => {
        if (cropperRef?.current && uploadedFile) {
            await cropperRef?.current?.getCanvas()?.toBlob(
                async (blob) => {
                    if (blob) {
                        setCroppedFile(getFileFromBlob(blob, uploadedFile));
                    }
                },
                uploadedFile.type,
                1
            );
            setShowCropper(false);
        }
    };
    const onRotate = async (isCcw = false) => {
        if (cropperRef?.current && uploadedFile) {
            cropperRef?.current?.rotateImage(isCcw ? 90 : -90);
        }
    };
    const onZoom = async (isZoomOut = false) => {
        if (cropperRef?.current && uploadedFile) {
            cropperRef?.current?.zoomImage(isZoomOut ? 0.5 : 2);
        }
    };

    // form
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
    } = useForm<FormEntity>({
        resolver: yupResolver(schema(t)),
        mode: "onSubmit",
    });

    const onSubmitHandler = (formData: FormEntity) => {
        if (user) {
            setLoading(true);
            FormEntityApi.patchItemProfile<FormEntity, FormEntity>(
                user.id,
                formData
            )
                .then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        toast({
                            title: errorMessage,
                            status: "error",
                        });
                    } else if (response !== null) {
                        reloadUserAction();
                        toast({
                            title: t("cmn:message.save.success"),
                            status: "success",
                        });
                        reset();
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const {
        handleSubmit: changeEmailHandleSubmit,
        register: changeEmailRegister,
        formState: { errors: changeEmailErrors },
        reset: changeEmailReset,
    } = useForm<ChangeEmailPayload>({
        resolver: yupResolver(schemaChangeEmail(t)),
        mode: "onBlur",
    });

    const onSubmitChangeEmailHandler = (formData: ChangeEmailPayload) => {
        if (user) {
            setLoading(true);
            FormEntityApi.patchItemChangeEmail<
                ChangeEmailResponse,
                ChangeEmailPayload
            >(user.id, formData)
                .then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        toast({
                            title: errorMessage,
                            status: "error",
                        });
                    } else if (response !== null) {
                        toast({
                            title: t(
                                "padm.ProfilePage:toast.success.changeEmail"
                            ),
                            status: "success",
                        });
                        changeEmailReset();
                        formContainerState.close();
                        logoutAction();
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const changePasswordHandler = async () => {
        setLoading(true);
        UserApi.postUserChangePasswordRequest<
            ForgotPasswordResponse,
            ForgotPasswordPayload
        >({ email: `${user?.email}` })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    toast({
                        title: t(
                            "padm.ProfilePage:toast.success.passwordReset"
                        ),
                        status: "success",
                    });
                    reset();
                    logoutAction();
                }
            })
            .finally(() => setLoading(false));
    };

    const renderUserForm = () => {
        return (
            <form
                id="app-form-profile"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(onSubmitHandler)();
                }}
            >
                {loading && <AppLoader />}
                <VStack gap={6} maxW={"600px"}>
                    <Box position="relative" w="100%">
                        <Text textStyle={"heading5"}>Personal Information</Text>
                        <Divider />
                    </Box>
                    <AppFormControl>
                        <Box textAlign={"center"}>
                            <Avatar
                                size={"xl"}
                                name={user?.fullNameNs}
                                src={useResolveUploadedPath(
                                    APPC.BE.Upload.FILETYPE_USER_AVATAR,
                                    user?.avatar,
                                    croppedFile
                                )}
                            >
                                <Box
                                    display={"flex"}
                                    pos={"absolute"}
                                    bottom={2}
                                    right={2}
                                    bg="#fff"
                                    borderRadius={"full"}
                                    padding={1}
                                >
                                    <AppIcon
                                        name="icl-pencil"
                                        color="#000"
                                        w="1rem"
                                    />
                                </Box>
                            </Avatar>
                            <Input
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    const file = e.target.files?.[0];
                                    if (file) {
                                        setUploadedFile(file);
                                        setShowCropper(true);
                                    }
                                    e.target.value = "";
                                }}
                                opacity={0}
                                position={"absolute"}
                                w={"full"}
                                h={"full"}
                                top={0}
                                left={0}
                            />
                            {showCropper && uploadedFile && (
                                <Box border={"1px solid #000"} p={4} mt={4}>
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <Cropper
                                            ref={cropperRef}
                                            src={URL.createObjectURL(
                                                uploadedFile
                                            )}
                                            className={"cropper"}
                                            stencilProps={{
                                                aspectRatio:
                                                    APPC.BE.Upload
                                                        .FILETYPEINFO_USER_AVATAR
                                                        .ratio,
                                            }}
                                        />
                                    </Suspense>
                                    <HStack
                                        justify={"space-between"}
                                        mt={4}
                                        wrap={"wrap"}
                                    >
                                        <HStack spacing={2}>
                                            <Button
                                                variant={"outline"}
                                                size={"sm"}
                                                className="btn-icon-sm"
                                                onClick={() => onZoom()}
                                            >
                                                <AppIcon
                                                    name="icl-zoom-in"
                                                    w="1rem"
                                                />
                                            </Button>
                                            <Button
                                                variant={"outline"}
                                                size={"sm"}
                                                className="btn-icon-sm"
                                                onClick={() => onZoom(true)}
                                            >
                                                <AppIcon
                                                    name="icl-zoom-out"
                                                    w="1rem"
                                                />
                                            </Button>
                                            <Button
                                                variant={"outline"}
                                                size={"sm"}
                                                className="btn-icon-sm"
                                                onClick={() => onRotate()}
                                            >
                                                <AppIcon
                                                    name="icl-action-undo"
                                                    w="1rem"
                                                />
                                            </Button>
                                            <Button
                                                variant={"outline"}
                                                size={"sm"}
                                                className="btn-icon-sm"
                                                onClick={() => onRotate(true)}
                                            >
                                                <AppIcon
                                                    name="icl-action-redo"
                                                    w="1rem"
                                                />
                                            </Button>
                                        </HStack>
                                        <HStack spacing={2}>
                                            <Button
                                                variant={"outline"}
                                                size={"sm"}
                                                className="btn-icon-sm"
                                                onClick={() => {
                                                    setUploadedFile(undefined);
                                                    setShowCropper(false);
                                                }}
                                            >
                                                <AppIcon
                                                    name="icl-x"
                                                    w="1rem"
                                                />
                                            </Button>
                                            <Button
                                                variant={"primary"}
                                                size={"sm"}
                                                className="btn-icon-sm"
                                                onClick={onCropperDone}
                                            >
                                                <AppIcon
                                                    name="icl-check-alt"
                                                    w="1rem"
                                                />
                                            </Button>
                                        </HStack>
                                    </HStack>
                                </Box>
                            )}
                        </Box>
                    </AppFormControl>
                    <AppFormControl label={t("ent.User:email.label")}>
                        <Input
                            type="email"
                            placeholder={t("ent.User:email.placeholder")}
                            isDisabled={true}
                            defaultValue={user?.email}
                        />
                    </AppFormControl>
                    <HStack w="100%">
                        <Button
                            w="50%"
                            variant={"destructive"}
                            onClick={() => formContainerState.open(0)}
                        >
                            <AppIcon name="icl-mail" w="1rem" />
                            <Text ml="2">Change Email</Text>
                        </Button>
                        <Button
                            w="50%"
                            variant={"destructive"}
                            onClick={onOpenChangePassword}
                        >
                            <AppIcon name="icl-key-alt" />
                            <Text ml="2">Change Password</Text>
                        </Button>
                    </HStack>
                    <AppFormControl
                        label={t("ent.User:firstName.label")}
                        isInvalid={!!errors?.firstName}
                        message={errors?.firstName?.message}
                    >
                        <Input
                            type="text"
                            placeholder={t("ent.User:firstName.placeholder")}
                            {...register("firstName")}
                            defaultValue={user?.firstName}
                        />
                    </AppFormControl>
                    <AppFormControl
                        label={t("ent.User:lastName.label")}
                        isInvalid={!!errors?.lastName}
                        message={errors?.lastName?.message}
                    >
                        <Input
                            type="text"
                            placeholder={t("ent.User:lastName.placeholder")}
                            {...register("lastName")}
                            defaultValue={user?.lastName}
                        />
                    </AppFormControl>
                    <Box alignSelf={"end"}>
                        <Button type="submit" form="app-form-profile">
                            {t("cmn:button.save")}
                        </Button>
                    </Box>
                </VStack>
            </form>
        );
    };

    const renderChangeEmailModal = () => {
        return (
            <AppFormContainer
                title={t("padm.ProfilePage:modal.changeEmail.title")}
                state={formContainerState}
                type={"modal"}
                size={"lg"}
            >
                <form
                    id="app-form"
                    onSubmit={(e) => {
                        e.preventDefault();
                        changeEmailHandleSubmit(onSubmitChangeEmailHandler)();
                    }}
                >
                    <VStack gap={5} py={6}>
                        <AppIcon
                            name="icl-mail"
                            w="2.5rem"
                            color={"var(--chakra-colors-destructive)"}
                        />
                        <AppFormControl label={t("ent.User:email.label")}>
                            <>{`${user?.email}`}</>
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.User:newEmail.label")}
                            isInvalid={!!changeEmailErrors?.newEmail}
                            message={changeEmailErrors?.newEmail?.message}
                            isLoading={loading}
                        >
                            <Input
                                type="text"
                                placeholder={t("ent.User:newEmail.placeholder")}
                                {...changeEmailRegister("newEmail")}
                                defaultValue={changeEmailData.newEmail}
                            />
                        </AppFormControl>
                        <Text textStyle={"regularSm"}>
                            {t("padm.ProfilePage:alert.changeEmail.detail")}
                        </Text>
                    </VStack>
                </form>
            </AppFormContainer>
        );
    };

    const renderChangePasswordAlert = () => {
        return (
            <AppAlert
                title={t("padm.ProfilePage:alert.changePassword.title")}
                isOpen={isOpenChangePassword}
                onSuccess={changePasswordHandler}
                onCancel={onCloseChangePassword}
                successLable={t(
                    "padm.ProfilePage:alert.changePassword.button.success"
                )}
                cancelLable={t(
                    "padm.ProfilePage:alert.changePassword.button.cancel"
                )}
                size={"lg"}
            >
                <VStack py={6}>
                    <AppIcon
                        name="icl-key-alt"
                        w="2.5rem"
                        color={"var(--chakra-colors-destructive)"}
                    />
                    <Text textStyle={"boldMd"}>
                        {t("padm.ProfilePage:alert.changePassword.message")}
                    </Text>
                    <Text textStyle={"regularSm"}>
                        {t("padm.ProfilePage:alert.changePassword.detail")}
                    </Text>
                </VStack>
            </AppAlert>
        );
    };

    return (
        <>
            <AppPageHeader
                title={t("padm.ProfilePage:text.profile")}
            ></AppPageHeader>
            <Box p={6}>
                {renderUserForm()}
                {renderChangeEmailModal()}
                {isOpenChangePassword && renderChangePasswordAlert()}
            </Box>
        </>
    );
};
