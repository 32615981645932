import { useTrans } from "../../../hooks";
import { VStack } from "@chakra-ui/react";
import { AppPageHeader } from "../../../components";
import { DonationTransactionList as ListComponent } from "./DonationTransactionList";
import { useDonationTransactionCrud } from "./useDonationTransactionCrud";
import { useParams } from "react-router-dom";
import { DonationTransactionDetailView } from "./DonationTransactionDetailView";

export const DonationTransactionPage = () => {
    // hook
    const { t } = useTrans();
    const { id } = useParams();
    const {
        kaTableProps,
        actionOptions,
        actionClickHandler,
        formContainerState,
        data,
        loading,
    } = useDonationTransactionCrud(id || "");

    return (
        <>
            <AppPageHeader
                title={t("padm.DonationTransactionPage:text.pageTitle")}
            />
            <VStack p={6}>
                <ListComponent
                    kaTableProps={kaTableProps}
                    actionOptions={actionOptions}
                    actionClickHandler={actionClickHandler}
                />
            </VStack>
            {formContainerState.isOpen && (
                <DonationTransactionDetailView
                    formContainerState={formContainerState}
                    data={data}
                    loading={loading}
                />
            )}
        </>
    );
};
