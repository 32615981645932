import React, {
    FC,
    PropsWithChildren,
    createContext,
    useContext,
    useReducer,
} from "react";

import { APPC, ENUM, LSC } from "../config";
import { User } from "../apis";

type AuthContextAction =
    | { type: ENUM.AuthContextAction.LOGIN_SUCCESS; payload: User }
    | { type: ENUM.AuthContextAction.RELOAD_USER; payload: User }
    | { type: ENUM.AuthContextAction.LOGOUT };

type GrantType = {
    isSuperAdmin: boolean;
    isUser: boolean;
    isDoner: boolean;
};

interface AuthContextState {
    isAuthenticated: boolean;
    user: User | null;
    grant: GrantType;
}

const authToken = localStorage.getItem(LSC.APP_TOKEN_KEY);
const userStringify = localStorage.getItem(LSC.APP_USER_KEY);

const getGrant = (user: User): GrantType => {
    return {
        isSuperAdmin: user?.roles[0] === APPC.BE.User.ROLE_SUPER_ADMIN,
        isUser: user?.roles[0] === APPC.BE.User.ROLE_USER,
        isDoner: user?.roles[0] === APPC.BE.User.ROLE_DONER,
    };
};

const initialState: AuthContextState = {
    isAuthenticated: !!authToken,
    user: userStringify ? JSON.parse(userStringify) : null,
    grant: getGrant(userStringify ? JSON.parse(userStringify) : null),
};

export const AuthContext = createContext<{
    state: AuthContextState;
    dispatch: React.Dispatch<AuthContextAction>;
}>({
    state: initialState,
    dispatch: () => null,
});

function reducer(
    state: AuthContextState,
    action: AuthContextAction
): AuthContextState {
    switch (action.type) {
        case ENUM.AuthContextAction.LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
                grant: getGrant(action.payload),
            };

        case ENUM.AuthContextAction.RELOAD_USER:
            return {
                ...state,
                user: action.payload,
                grant: getGrant(action.payload),
            };

        case ENUM.AuthContextAction.LOGOUT:
            localStorage.removeItem(LSC.APP_TOKEN_KEY);
            localStorage.removeItem(LSC.APP_USER_KEY);
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };

        default:
            return state;
    }
}

export const AuthProvider: FC<PropsWithChildren> = ({
    children,
}: PropsWithChildren): JSX.Element => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AuthContext.Provider value={{ state, dispatch }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthData = (): AuthContextState => {
    const context = useContext(AuthContext);
    if (context === null) {
        throw new Error("useAuthData must be used within a AuthProvider");
    }
    return context.state;
};
