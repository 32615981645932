import { APPC } from "../config";

type useAppUtilType = {
    getFileFromBlob: (blob: Blob, file: File) => File;
    statusVariant: (status: string) => string;
};

export function useAppUtil(): useAppUtilType {
    const getFileFromBlob = (blob: Blob, file: File) =>
        new File([blob], file.name, {
            type: file.type,
            lastModified: file.lastModified,
        });

    const statusVariant = (status: string) => {
        switch (status) {
            case APPC.BE.DonationTransaction.TRANSACTION_STATUS_CREATED:
                return "red";
            case APPC.BE.DonationTransaction.TRANSACTION_STATUS_SUCCESS:
                return "green";
            case APPC.BE.DonationTransaction.TRANSACTION_STATUS_FAILED:
                return "red";
            default:
                return "";
        }
    };
    return {
        getFileFromBlob,
        statusVariant,
    };
}
