import { useTrans } from "./useTrans";
import { cPath } from "../config";
import { NavItem } from "../containers/AppNav/AppNav";
import { useAuthData } from "../contexts";

export const useNavItems = (type: string = "main"): NavItem[] => {
    const { t } = useTrans();
    const { grant } = useAuthData();

    const userNavItems: NavItem[] = [
        {
            label: t("nav:profile"),
            url: cPath("ADMIN.PROFILE_PAGE"),
            iconName: "icl-address-card",
        },
        {
            label: t("nav:logout"),
            url: cPath("ADMIN.LOGOUT"),
            iconName: "icl-account-logout",
        },
    ];

    const mainNavItems: NavItem[] = [
        {
            label: t("nav:dashboard"),
            url: cPath("ADMIN.DASHBOARD_PAGE"),
            iconName: "icl-view-quilt",
            divider: true,
        },
    ];

    if (grant.isDoner) {
        mainNavItems.push({
            label: t("nav:myDonation"),
            url: cPath("ADMIN.MY_DONATION_TRANSACTION_PAGE"),
            iconName: "icl-donate",
            divider: true,
        });
    }
    if (grant.isSuperAdmin) {
        mainNavItems.push(
            {
                label: t("nav:administration"),
            },
            {
                label: t("nav:donation"),
                iconName: "icl-donate",
                children: [
                    {
                        label: t("nav:transaction"),
                        url: cPath("ADMIN.DONATION_TRANSACTION_PAGE"),
                        iconName: "icl-library-books",
                    },
                    {
                        label: t("nav:doner"),
                        url: cPath("ADMIN.DONER_PAGE"),
                        iconName: "icl-people",
                    },
                ],
            },
            {
                label: t("nav:page"),
                url: cPath("ADMIN.PAGE_PAGE"),
                iconName: "icl-page",
            },
            {
                label: t("nav:user"),
                url: cPath("ADMIN.USER_PAGE"),
                iconName: "icl-id-badge",
            }
        );
    }

    return type === "user" ? userNavItems : mainNavItems;
};
