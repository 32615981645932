import { BaseEntity } from "./BaseEntity";
import { User } from "./User";

type Customer = {
    reference: number;
    email: string;
    billingDetails: {
        address: string;
        city: string;
        country: string;
        firstName: string;
        lastName: string;
        title: string;
        zipCode: string;
    };
};

export class DonationTransaction extends BaseEntity {
    public amount: number = 0;
    public currency: string = "";
    public isSubscription: boolean = false;
    public effectDate?: string = "";
    public initialAmount?: number = 0;
    public initialAmountNumber?: number = 0;
    public rrule?: string = "";
    public customer: Customer = {
        reference: 0,
        email: "",
        billingDetails: {
            address: "",
            city: "",
            country: "",
            firstName: "",
            lastName: "",
            title: "",
            zipCode: "",
        },
    };
    public user: string | User = "";
    public requestPayload?: object = {};
    public responsePayload?: object = {};
    public status: string = "";
    public createdAt: string = "";

    constructor(model: Partial<DonationTransaction> = {}) {
        super(model?.id);
        Object.assign(this, model);
    }
}

export type PDonationTransaction = Partial<DonationTransaction>;
