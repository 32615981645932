import { useParams } from "react-router-dom";
import { useAuthAction } from "../../../hooks";
import { AppLoader } from "../../../components";

export const TokenLoginPage = () => {
    // hooks
    const { token } = useParams();
    const { isAuthenticated, tokenLoginAction } = useAuthAction();

    if (token && token.length > 0) {
        tokenLoginAction(token);
    }

    if (!isAuthenticated) {
        return (
            <div className={"vh-100 vw-100"}>
                <AppLoader />
            </div>
        );
    }

    return <></>;
};
