import ReactDOM from "react-dom/client";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { initTranslation } from "./services/Translation";
import Fonts from "./theme/fonts/Fonts";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./contexts";
import "./assets/scss/style.scss";
import { RecoilRoot } from "recoil";

initTranslation();

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    // <React.StrictMode>
    <ChakraProvider
        resetCSS
        theme={theme}
        toastOptions={{
            defaultOptions: {
                position: "top",
                isClosable: true,
                duration: 5000,
            },
        }}
    >
        <Fonts />
        <BrowserRouter>
            <RecoilRoot>
                <AuthProvider>
                    <App />
                </AuthProvider>
            </RecoilRoot>
        </BrowserRouter>
    </ChakraProvider>
    // </React.StrictMode>
);
