import { FC } from "react";
import { HStack, UseDisclosureReturn } from "@chakra-ui/react";
import { IKaTableProps, Table as KaTable } from "ka-table";
import { ICellTextProps } from "ka-table/props";
import {
    AppAlertDelete,
    AppOptionBoxItem,
    AppOptionsBox,
    KaPageSizeSelector,
} from "../../../components";
import { Page } from "../../../apis";

export const PageList: FC<{
    kaTableProps: IKaTableProps;
    actionOptions: AppOptionBoxItem[];
    actionClickHandler: (key: string, data?: Page) => void;
    deleteHandler: () => Promise<void>;
    deleteAlertState: UseDisclosureReturn;
}> = ({
    kaTableProps,
    actionOptions,
    actionClickHandler,
    deleteHandler,
    deleteAlertState,
}) => {
    return (
        <>
            <KaTable
                {...kaTableProps}
                childComponents={{
                    pagingSizes: {
                        content: (props) => <KaPageSizeSelector {...props} />,
                    },
                    cellText: {
                        content: (props: ICellTextProps) => {
                            switch (props?.column?.key) {
                                case "action":
                                    return (
                                        <HStack justify={"flex-end"} gap={3}>
                                            <AppOptionsBox<Page>
                                                options={actionOptions}
                                                data={props?.rowData}
                                                onClick={actionClickHandler}
                                            />
                                        </HStack>
                                    );
                            }
                        },
                    },
                }}
            />
            {deleteAlertState.isOpen && (
                <AppAlertDelete
                    isOpenDelete={deleteAlertState.isOpen}
                    deleteHandler={() => deleteHandler()}
                    onCloseDelete={deleteAlertState.onClose}
                />
            )}
        </>
    );
};
