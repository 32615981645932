import { useTrans } from "../../../hooks";
import { AppPageHeader } from "../../../components";

export const DashboardPage = () => {
    const { t } = useTrans();
    return (
        <>
            <AppPageHeader
                title={t("padm.DashboardPage:text.dashboard")}
            ></AppPageHeader>
        </>
    );
};
