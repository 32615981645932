export const APP_PROTOCOL = process.env.REACT_APP_PROTOCOL;
export const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
export const APP_BASEPATH = `${APP_PROTOCOL}://${APP_DOMAIN}`;
export const PAGE_SIZE = 10;
export const PAGE_SIZES = [10, 30, 50, 100];
export const FORM_CONTAINER = "drawer";

export const BE = {
    User: {
        ROLE_SUPER_ADMIN: "ROLE_SUPER_ADMIN",
        ROLE_USER: "ROLE_USER",
        ROLE_DONER: "ROLE_DONER",
    },
    Upload: {
        FILETYPE_USER_AVATAR: "USER_AVATAR",
        FILETYPEINFO_USER_AVATAR: {
            key: "USER_AVATAR",
            width: "200",
            height: "200",
            ratio: 1 / 1,
            maxSize: null,
            allowType: ["jpg", "jpeg", "png", "gif", "webp"],
            isSecure: false,
            path: "user_avatar",
        },
    },
    DonationTransaction: {
        TRANSACTION_STATUS_CREATED: "CREATED",
        TRANSACTION_STATUS_SUCCESS: "SUCCESS",
        TRANSACTION_STATUS_FAILED: "FAILED",
    },
};
