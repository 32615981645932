import { FC } from "react";
import {
    FormContainerStateReturnType,
    useAppUtil,
    useTrans,
} from "../../../hooks";
import { DonationTransaction } from "../../../apis";
import {
    Badge,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Table,
    TableContainer,
    Tbody,
    Td,
    Tr,
    Text,
} from "@chakra-ui/react";
import { AppLoader } from "../../../components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export const DonationTransactionDetailView: FC<{
    formContainerState: FormContainerStateReturnType;
    data: DonationTransaction;
    loading: boolean;
}> = ({ formContainerState, data, loading }) => {
    const { t } = useTrans();
    const { statusVariant } = useAppUtil();
    dayjs.extend(utc);
    return (
        <>
            {loading ? (
                <AppLoader />
            ) : (
                <Drawer
                    size={"lg"}
                    isOpen={formContainerState.isOpen}
                    placement="right"
                    onClose={() => formContainerState.close()}
                >
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>
                            {t("padm.DonationTransactionPage:text.pageTitle")}
                        </DrawerHeader>
                        <DrawerBody>
                            <TableContainer>
                                <Table variant="simple">
                                    <Tbody>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.DonationTransaction:id.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>{data?.id}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.DonationTransaction:amount.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>
                                                &euro; {data?.amount / 100}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.DonationTransaction:createdAt.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>
                                                {dayjs(data?.createdAt)
                                                    .utc()
                                                    .format(
                                                        "DD-MM-YYYY HH:mm:ss"
                                                    )}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.DonationTransaction:status.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>
                                                <Badge
                                                    variant={statusVariant(
                                                        data.status
                                                    )}
                                                >
                                                    {data.status}
                                                </Badge>
                                            </Td>
                                        </Tr>
                                        {data?.isSubscription && (
                                            <>
                                                <Tr mb={2}>
                                                    <Td pe={2}>
                                                        <Text
                                                            textStyle={
                                                                "semiBoldLg"
                                                            }
                                                        >
                                                            {t(
                                                                "ent.DonationTransaction:subscription.label"
                                                            )}
                                                        </Text>
                                                    </Td>
                                                    <Td></Td>
                                                </Tr>
                                                <Tr>
                                                    <Td pe={2}>
                                                        {t(
                                                            "ent.DonationTransaction:effectDate.label"
                                                        )}
                                                    </Td>
                                                    <Td ps={2}>
                                                        {dayjs(data?.effectDate)
                                                            .utc()
                                                            .format(
                                                                "DD-MM-YYYY HH:mm:ss"
                                                            )}
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td pe={2}>
                                                        {t(
                                                            "ent.DonationTransaction:initialAmount.label"
                                                        )}
                                                    </Td>
                                                    <Td ps={2}>
                                                        {data?.initialAmount}
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td pe={2}>
                                                        {t(
                                                            "ent.DonationTransaction:initialAmountNumber.label"
                                                        )}
                                                    </Td>
                                                    <Td ps={2}>
                                                        {
                                                            data?.initialAmountNumber
                                                        }
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td pe={2}>
                                                        {t(
                                                            "ent.DonationTransaction:rrule.label"
                                                        )}
                                                    </Td>
                                                    <Td ps={2}>
                                                        {data?.rrule}
                                                    </Td>
                                                </Tr>
                                            </>
                                        )}
                                        <Tr mb={2}>
                                            <Td pe={2}>
                                                <Text textStyle={"semiBoldLg"}>
                                                    {t(
                                                        "ent.DonationTransaction:customer.label"
                                                    )}
                                                </Text>
                                            </Td>
                                            <Td></Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.DonationTransaction:customer.reference.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>
                                                {data?.customer?.reference}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.DonationTransaction:customer.email.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>
                                                {data?.customer?.email}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.DonationTransaction:customer.billingDetails.name.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>
                                                {`${data?.customer?.billingDetails?.title} ${data?.customer?.billingDetails?.firstName} ${data?.customer?.billingDetails?.lastName}`}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.DonationTransaction:customer.billingDetails.address.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>
                                                <Text whiteSpace="pre-wrap">
                                                    {
                                                        data?.customer
                                                            ?.billingDetails
                                                            ?.address
                                                    }
                                                </Text>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.DonationTransaction:customer.billingDetails.zipCode.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>
                                                {
                                                    data?.customer
                                                        ?.billingDetails
                                                        ?.zipCode
                                                }
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.DonationTransaction:customer.billingDetails.city.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>
                                                {
                                                    data?.customer
                                                        ?.billingDetails?.city
                                                }
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.DonationTransaction:customer.billingDetails.country.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>
                                                {
                                                    data?.customer
                                                        ?.billingDetails
                                                        ?.country
                                                }
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            )}
        </>
    );
};
