import { FC, useEffect, useState } from "react";
import { FormContainerStateReturnType, useTrans } from "../../../hooks";
import { User, UserApi } from "../../../apis";
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Table,
    TableContainer,
    Tbody,
    Td,
    Tr,
    useToast,
    Text,
} from "@chakra-ui/react";
import { AppLoader } from "../../../components";

export const DonerDetailView: FC<{
    formContainerState: FormContainerStateReturnType;
}> = ({ formContainerState }) => {
    const { t } = useTrans();
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<User>(new User());

    useEffect(() => {
        setLoading(true);
        if (formContainerState.entityId) {
            UserApi.getItem<User>(formContainerState.entityId)
                .then(({ errorMessage, isNotFound, response }) => {
                    if (errorMessage) {
                        toast({
                            title: errorMessage,
                            status: "error",
                        });
                    } else if (isNotFound) {
                        toast({
                            title: t("cmn:message.entityNotFound"),
                            status: "error",
                        });
                    } else if (response !== null) {
                        setData(response);
                    }
                })
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, [formContainerState.entityId]);

    return (
        <>
            {loading ? (
                <AppLoader />
            ) : (
                <Drawer
                    size={"lg"}
                    isOpen={formContainerState.isOpen}
                    placement="right"
                    onClose={() => formContainerState.close()}
                >
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>
                            {t("padm.UserPage:text.doner")}
                        </DrawerHeader>
                        <DrawerBody>
                            <TableContainer>
                                <Table variant="simple">
                                    <Tbody>
                                        <Tr>
                                            <Td pe={2}>
                                                {t("ent.User:id.label")}
                                            </Td>
                                            <Td ps={2}>{data?.id}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t("ent.User:name.label")}
                                            </Td>
                                            <Td ps={2}>{data?.fullName}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t("ent.User:email.label")}
                                            </Td>
                                            <Td ps={2}>{data?.email}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t("ent.User:address.label")}
                                            </Td>
                                            <Td ps={2}>
                                                <Text whiteSpace="pre-wrap">
                                                    {data?.address}
                                                </Text>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t("ent.User:postalCode.label")}
                                            </Td>
                                            <Td ps={2}>{data?.postalCode}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t("ent.User:city.label")}
                                            </Td>
                                            <Td ps={2}>{data?.city}</Td>
                                        </Tr>
                                        <Tr mb={2}>
                                            <Td pe={2}>
                                                {t("ent.User:country.label")}
                                            </Td>
                                            <Td ps={2}>{data?.country}</Td>
                                        </Tr>
                                        {data?.isCompany && (
                                            <>
                                                <Tr mb={2}>
                                                    <Td pe={2}>
                                                        <Text
                                                            textStyle={
                                                                "semiBoldLg"
                                                            }
                                                        >
                                                            {t(
                                                                "ent.User:company.label"
                                                            )}
                                                        </Text>
                                                    </Td>
                                                    <Td></Td>
                                                </Tr>
                                                <Tr>
                                                    <Td pe={2}>
                                                        {t(
                                                            "ent.User:companyName.label"
                                                        )}
                                                    </Td>
                                                    <Td ps={2}>
                                                        {data?.companyName}
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td pe={2}>
                                                        {t(
                                                            "ent.User:siren.label"
                                                        )}
                                                    </Td>
                                                    <Td ps={2}>
                                                        {data?.siren}
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td pe={2}>
                                                        {t(
                                                            "ent.User:legalStatus.label"
                                                        )}
                                                    </Td>
                                                    <Td ps={2}>
                                                        {data?.legalStatus}
                                                    </Td>
                                                </Tr>
                                            </>
                                        )}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            )}
        </>
    );
};
