import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormContainerState, useTrans } from "../../../hooks";
import {
    Button,
    VStack,
    HStack,
    Divider,
    Center,
    Text,
    Hide,
} from "@chakra-ui/react";
import { AppIcon, AppPageHeader } from "../../../components";
import { UserList as ListComponent } from "./UserList";
import { UserForm as FormComponent } from "./UserForm";
import { AppSearch } from "../../../containers";
import { cPath } from "../../../config";
import { DonerDetailView } from "./DonerDetailView";

export const UserPage = () => {
    // hook
    const { t } = useTrans();
    const { id } = useParams();
    const formContainerState = useFormContainerState();

    const [search, setSearch] = useState("");

    const isDonerPage =
        window.location.pathname === cPath("ADMIN.DONER_PAGE", {});

    useEffect(() => {
        if (id) {
            formContainerState.open(id);
        }
    }, [id]);

    return (
        <>
            <AppPageHeader
                title={
                    isDonerPage
                        ? t("padm.UserPage:text.doner")
                        : t("padm.UserPage:text.user")
                }
            >
                <HStack gap={4} py={{ base: 3.5, sm: 4, md: "1.125rem" }}>
                    <AppSearch onSearch={(value) => setSearch(value)} />
                    {!isDonerPage && (
                        <>
                            <Center height="2.1875rem">
                                <Divider
                                    orientation="vertical"
                                    borderColor={"var(--chakra-colors-greyT80)"}
                                />
                            </Center>

                            <Button
                                variant={"primary"}
                                onClick={() => formContainerState.open(0)}
                            >
                                <AppIcon name="ics-plus-circle" w="1rem" />
                                <Hide below="md">
                                    <Text ml={2}>
                                        {t("padm.UserPage:button.addUser")}
                                    </Text>
                                </Hide>
                            </Button>
                        </>
                    )}
                </HStack>
            </AppPageHeader>
            <VStack p={6}>
                <ListComponent
                    formContainerState={formContainerState}
                    search={search}
                />
            </VStack>
            {formContainerState.isOpen && !isDonerPage && (
                <FormComponent formContainerState={formContainerState} />
            )}
            {formContainerState.isOpen && isDonerPage && (
                <DonerDetailView formContainerState={formContainerState} />
            )}
        </>
    );
};
