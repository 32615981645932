import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";

type AppFormControlProps = {
    children: JSX.Element;
    label?: string;
    isInvalid?: boolean;
    isDisabled?: boolean;
    message?: string;
    isLoading?: boolean;
};

export const AppFormControl = ({
    children,
    label = "",
    isInvalid = false,
    isDisabled = false,
    message = "",
    isLoading = false,
}: AppFormControlProps) =>
    isLoading ? (
        <></>
    ) : (
        <FormControl isInvalid={isInvalid} isDisabled={isDisabled}>
            {label && <FormLabel>{label}</FormLabel>}
            {children}
            {message && <FormErrorMessage>{message}</FormErrorMessage>}
        </FormControl>
    );
