import { FC, useEffect, useRef, useState } from "react";
import {
    FormContainerStateReturnType,
    useAppTable,
    useTrans,
} from "../../../hooks";
import { Canceler } from "axios";
import { HStack, useToast, Text, useDisclosure, Badge } from "@chakra-ui/react";
import { User as ListEntity, UserApi as ListEntityApi } from "../../../apis";
import { DataType, SortingMode, Table as KaTable } from "ka-table";
import { ICellTextProps } from "ka-table/props";
import {
    AppAlertDelete,
    AppOptionBoxItem,
    AppOptionsBox,
    KaPageSizeSelector,
} from "../../../components";
import { APPC, cPath } from "../../../config";
import { Column } from "ka-table/models";
import { useNavigate } from "react-router-dom";

export const UserList: FC<{
    formContainerState: FormContainerStateReturnType;
    search?: string;
}> = ({ formContainerState, search }) => {
    // hook
    const { t } = useTrans();
    const toast = useToast();
    const {
        isOpen: isOpenDelete,
        onOpen: onOpenDelete,
        onClose: onCloseDelete,
    } = useDisclosure();
    const navigate = useNavigate();

    // state & const
    const [loading, setLoading] = useState(true);
    const [selectId, setSelectId] = useState(0);
    const [list, setList] = useState<ListEntity[]>([]);
    const cancelTokenSourcesRef = useRef<Canceler[]>([]);

    const isDonerPage =
        window.location.pathname === cPath("ADMIN.DONER_PAGE", {});

    // table
    const {
        table,
        pageIndex,
        pagesCount,
        pageSize,
        sortColumn,
        sortOrder,
        setPagesCount,
    } = useAppTable("firstName");

    const columns: Column[] = [
        {
            key: "firstName",
            title: t("ent.User:name.label"),
            dataType: DataType.String,
        },
        {
            key: "email",
            title: t("ent.User:email.label"),
            dataType: DataType.String,
        },
        {
            key: "userType",
            title: t("ent.User:userType.label"),
            dataType: DataType.String,
            isSortable: false,
        },
        {
            key: "action",
            title: "",
            style: { textAlign: "right" },
            dataType: DataType.Object,
            width: 50,
            isSortable: false,
        },
    ];

    const deleteHandler = async () => {
        if (selectId < 1) {
            return;
        }
        setLoading(true);
        ListEntityApi.deleteItem(selectId)
            .then(({ errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else {
                    fetchListData();
                }
            })
            .finally(() => setLoading(false));
    };

    const fetchListData = () => {
        setLoading(true);
        const params = {
            itemsPerPage: pageSize,
        };
        params[`order[${sortColumn}]`] = sortOrder;
        if (search) {
            params["firstName"] = search;
        }

        const method = isDonerPage ? "getCollectionDoner" : "getCollection";
        ListEntityApi[method]<ListEntity>(pageIndex + 1, params, (c) => {
            cancelTokenSourcesRef.current.push(c);
        })
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    setPagesCount(Math.ceil(response.totalItems / pageSize));
                    setList(response.items);
                }
            })
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        if (!formContainerState.isOpen) {
            fetchListData();
        }
    }, [
        pageIndex,
        pageSize,
        sortColumn,
        sortOrder,
        search,
        formContainerState.isOpen,
    ]);

    const actionOptions: AppOptionBoxItem[] = [
        {
            key: "edit",
            label: t("cmn:option.edit"),
            iconName: "icl-pencil",
        },
        {
            key: "delete",
            label: t("cmn:option.delete"),
            iconName: "icl-trash",
        },
    ];

    if (isDonerPage) {
        columns.splice(2, 1);
        actionOptions.splice(0, 2);
        actionOptions.push({
            key: "detail",
            label: t("cmn:option.detail"),
            iconName: "icl-eye",
        });
        actionOptions.push({
            key: "transactions",
            label: t("cmn:option.transactions"),
            iconName: "icl-library-books",
        });
    }

    const filterActionOptions = (props: ICellTextProps) => {
        return actionOptions.map((o) => {
            if (o.key === "delete") {
                o.isDisabled =
                    props?.rowData?.userType === APPC.BE.User.ROLE_SUPER_ADMIN;
            }
            return o;
        });
    };

    const actionClickHandler = (key: string, data?: ListEntity) => {
        if (!data) {
            return;
        }
        switch (key) {
            case "edit":
                formContainerState.open(data?.id);
                break;
            case "delete":
                setSelectId(data?.id);
                onOpenDelete();
                break;
            case "detail":
                formContainerState.open(data?.id);
                break;
            case "transactions":
                navigate(
                    cPath("ADMIN.DONATION_TRANSACTION_USER_PAGE", {
                        id: data?.id,
                    })
                );
                break;
        }
    };

    return (
        <>
            <KaTable
                table={table}
                columns={columns}
                data={list || []}
                loading={{
                    enabled: loading,
                }}
                paging={{
                    enabled: true,
                    pageSizes: APPC.PAGE_SIZES,
                    pageSize,
                    pageIndex,
                    pagesCount,
                }}
                rowKeyField={"id"}
                sortingMode={SortingMode.SingleRemote}
                childComponents={{
                    pagingSizes: {
                        content: (props) => <KaPageSizeSelector {...props} />,
                    },
                    cellText: {
                        content: (props: ICellTextProps) => {
                            switch (props?.column?.key) {
                                case "firstName":
                                    return (
                                        <Text textStyle={"mediumSm"}>
                                            {props?.rowData?.fullName}
                                        </Text>
                                    );
                                case "userType":
                                    return (
                                        <Badge variant={"secondary"}>
                                            {t(
                                                `con.User:${props?.rowData?.userType}`
                                            )}
                                        </Badge>
                                    );
                                case "action":
                                    return (
                                        <HStack justify={"flex-end"} gap={3}>
                                            <AppOptionsBox
                                                options={filterActionOptions(
                                                    props
                                                )}
                                                data={props?.rowData}
                                                onClick={actionClickHandler}
                                            />
                                        </HStack>
                                    );
                            }
                        },
                    },
                }}
            />

            {isOpenDelete && selectId && (
                <AppAlertDelete
                    isOpenDelete
                    deleteHandler={deleteHandler}
                    onCloseDelete={onCloseDelete}
                />
            )}
        </>
    );
};
