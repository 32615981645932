import { BaseEntity } from "./BaseEntity";

export class Page extends BaseEntity {
    public name: string = "";
    public slug: string = "";
    public content: string = "";

    constructor(model: Partial<Page> = {}) {
        super(model?.id);
        Object.assign(this, model);
    }
}

export type PPage = Partial<Page>;
