const GlobalStyle = {
    global: () => ({
        "html, body": {
            color: "var(--chakra-colors-contentT20)",
            height: "100%",
            scrollbarColor: "var(--chakra-colors-primary) transparent",
            scrollbarWidth: "thin",
            backgroundColor: "var(--chakra-colors-white)",
        },
        ".btn-icon-lg": {
            justifyContent: "center !important",
            padding: "0.75rem !important",
            width: "unset !important",
        },
        ".btn-icon, .btn-icon-md": {
            justifyContent: "center !important",
            padding: "0.625rem !important",
            width: "unset !important",
        },
        ".btn-icon-sm": {
            justifyContent: "center !important",
            padding: "0.5rem !important",
            width: "unset !important",
        },
        ".btn-icon-xs": {
            justifyContent: "center !important",
            padding: "0.325rem !important",
            width: "unset !important",
        },
        ".close-dropdown": {
            display: "none !important",
        },
        ".rotate-180": {
            transform: "rotate(180deg)",
        },
        ".btn-icon-header": {
            width: "1.25rem !important",
            height: "1.25rem !important",
            justifyContent: "center !important",
            padding: "0.1rem !important",
        },
        ".chakra-input__group": {
            minWidth: "unset !important",
        },
    }),
};

export default GlobalStyle;
