import { FC } from "react";
import { HStack, Text, Badge } from "@chakra-ui/react";
import { IKaTableProps, Table as KaTable } from "ka-table";
import { ICellTextProps } from "ka-table/props";
import {
    AppOptionBoxItem,
    AppOptionsBox,
    KaPageSizeSelector,
} from "../../../components";
import { DonationTransaction } from "../../../apis";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export const DonationTransactionList: FC<{
    kaTableProps: IKaTableProps;
    actionOptions: AppOptionBoxItem[];
    actionClickHandler: (key: string, data?: DonationTransaction) => void;
}> = ({ kaTableProps, actionOptions, actionClickHandler }) => {
    dayjs.extend(utc);
    return (
        <>
            <KaTable
                {...kaTableProps}
                childComponents={{
                    pagingSizes: {
                        content: (props) => <KaPageSizeSelector {...props} />,
                    },
                    cellText: {
                        content: (props: ICellTextProps) => {
                            switch (props?.column?.key) {
                                case "createdAt":
                                    return (
                                        <Text>
                                            {dayjs(props?.rowData?.startTime)
                                                .utc()
                                                .format("DD-MM-YYYY HH:mm:ss")}
                                        </Text>
                                    );
                                case "amount":
                                    return (
                                        <Text textStyle={"mediumSm"}>
                                            &euro;{" "}
                                            {props?.rowData?.amount / 100}
                                        </Text>
                                    );
                                case "status":
                                    return (
                                        <Badge
                                            variant={
                                                props?.rowData?.status ===
                                                "SUCCESS"
                                                    ? "green"
                                                    : "red"
                                            }
                                        >
                                            {props?.rowData?.status}
                                        </Badge>
                                    );
                                case "action":
                                    return (
                                        <HStack justify={"flex-end"} gap={3}>
                                            <AppOptionsBox<DonationTransaction>
                                                options={actionOptions}
                                                data={props?.rowData}
                                                onClick={actionClickHandler}
                                            />
                                        </HStack>
                                    );
                            }
                        },
                    },
                }}
            />
        </>
    );
};
